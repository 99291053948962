import { Injectable } from '@angular/core';

interface IError {
	message: string;
	state: 'show' | 'hide';
}

@Injectable({
	providedIn: 'root',
})
export class AvailabilityErrorService {
	private _error: IError = { message: '', state: 'hide' };

	get availabilityError(): IError {
		return this._error;
	}

	set availabilityError({ message, state }: IError) {
		this._error = { message, state };
	}

	showAvailabilityError(message: string, timeout?: number): void {
		const visibilityTime = timeout ? timeout : 5000;

		this.availabilityError = { message, state: 'show' };

		setTimeout(() => {
			this.availabilityError.state = 'hide';
		}, visibilityTime);
	}
}
